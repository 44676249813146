<template>
  <div class="mt-3">
    <div class="container-fluid mb-2">
      <div class="row mt-2" style="background-color:white;padding:10px">
        <div class="col-md-8">
          <h5>Announcement Details</h5>
          <table class="table table-sm ">
            <tr>
              <th>Title</th>
              <td>{{ socialMediaDetails.title }}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{{ socialMediaDetails.description }}</td>
            </tr>
            <tr>
              <th>URL</th>
              <td>{{ socialMediaDetails.url }}</td>
            </tr>
            <tr v-if="socialMediaDetails.assignment_level">
              <th>Assignment Level</th>
              <td>
                <span
                  v-if="socialMediaDetails.assignment_level === 'project_level'"
                  >Project Level</span
                >
                <span
                  v-else-if="
                    socialMediaDetails.assignment_level === 'zone_level'
                  "
                  >Zone Level</span
                >
                <span
                  v-else-if="
                    socialMediaDetails.assignment_level === 'plot_level'
                  "
                  >Plot Level</span
                >
                <span
                  v-else-if="socialMediaDetails.assignment_level === 'general'"
                  >General</span
                >
              </td>
            </tr>
            <template v-if="socialMediaDetails.assignment_level !== 'general'">
              <tr v-if="socialMediaDetails._project_id !== null">
                <th>Project</th>
                <td>{{ socialMediaDetails._project_id.name }}</td>
              </tr>
              <tr v-if="socialMediaDetails._zone_id !== null">
                <th>Zone</th>
                <td>{{ socialMediaDetails._zone_id.code }}</td>
              </tr>
              <tr v-if="socialMediaDetails._plot_id !== null">
                <th>Plot</th>
                <td>{{ socialMediaDetails._plot_id.code }}</td>
              </tr>
            </template>
            <tr>
              <th>Status</th>
              <td>
                <span v-if="socialMediaDetails.status">
                  Active
                </span>
                <span v-else>
                  InActive
                </span>
              </td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>
                {{ socialMediaDetails.created_ts | moment('ddd,Do MMM  YYYY') }}
              </td>
            </tr>
            <tr>
              <th>Images</th>
              <td v-if="socialMediaDetails.images[0] != ''">
                <b-row no-gutters>
                  <b-col
                    md="4"
                    v-for="image in socialMediaDetails.images"
                    :key="image"
                  >
                    <img
                      :src="image"
                      height="100px"
                      width="200px"
                      alt=""
                      style="border-radius: 12px; margin-bottom: 14px"
                    />
                  </b-col>
                </b-row>
              </td>
              <td v-else class="text-muted">No Images Found</td>
            </tr>
            <tr>
              <th>Videos</th>
              <td v-if="socialMediaDetails.videos[0] != ''">
                <b-row no-gutters>
                  <b-col
                    md="4"
                    v-for="video in socialMediaDetails.videos"
                    :key="video"
                  >
                    <video
                      height="100px"
                      width="200px"
                      controls
                      style="margin-bottom: 14px"
                    >
                      <source :src="video" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </b-col>
                </b-row>
              </td>
              <td v-else class="text-muted">No Videos Found</td>
            </tr>
          </table>
        </div>
        <div class="col-md-4 text-right">
          <img
            :src="socialMediaDetails.image"
            class="img-fluid img-thumbnail"
            alt=""
          />
        </div>
      </div>
      <b-row>
        <b-col md="12">
          <b-table
            class="dataTable rounded-left"
            :items="commentsItems"
            :fields="commentsFields"
            :outlined="true"
            head-variant="none"
            table-variant="none"
            :caption-top="true"
            :busy="isBusyComments"
            show-empty
          >
            <template #empty="scope">
              <h4>{{ scope.emptyText }}</h4>
            </template>
            <template #table-caption>Comments</template>
            <template #cell(#)="data">{{ data.index + 1 }}</template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(created_ts)="data">
              {{ data.item.created_ts | moment('DD-MM-YYYY hh:mm:ss A') }}
            </template>

            
          </b-table>
          <b-modal id="my-modal" title="Reply">
            <b-form-group
              label="Comment"
              label-for="name-input"
              invalid-feedback="Comment is required"
            >
              <b-form-input id="name-input" required></b-form-input>
            </b-form-group>
          </b-modal>
        </b-col>
        <b-col md="12">
          <b-table
            class="dataTable rounded-left"
            :items="likesItems"
            :fields="likesFields"
            :outlined="true"
            head-variant="none"
            table-variant="none"
            :caption-top="true"
            :busy="isBusy"
            show-empty
          >
            <template #empty="scope">
              <h4>{{ scope.emptyText }}</h4>
            </template>

            <template #table-caption>Likes</template>
            <template #cell(#)="data">{{ data.index + 1 }}</template>
            <template #cell(created_ts)="data">
              {{ data.item.created_ts | moment('DD-MM-YYYY hh:mm:ss A') }}
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>

export default {
 

  data() {
    return {
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      filter: null,
      isBusy: false,
      isBusyComments: false,
      items: [],
      socialMediaDetails: false,
      commentsFields: [
        '#',

        {
          key: '_user_id.name',
          label: 'Name',
        },
        {
          key: 'comment',
          label: 'Comments',
        },
        {
          key: 'admin_comment.comment',
          label: 'Admin Comments',
        },
        {
          key: 'created_ts',
          label: 'Comment Date',
        },
     
      ],

      likesFields: [
        '#',
        {
          key: '_user_id.name',
          label: 'Name',
        },
        {
          key: 'created_ts',
          label: 'Date',
        },
      ],

      likesItems: [],
      commentsItems: [],
    };
  },
  mounted() {
    this.getOrgDetails();
    this.getComments();
    this.getLikes();
  },
  methods: {
    commentListReload(value) {
      if (value) {
        this.getComments();
      }
    },

    getComments() {
      this.isBusyComments = true;
      let id = this.$route.params.id;
      window.axios.get('comments/' + id).then((resp) => {
        if (resp.data.success) {
          this.commentsItems = resp.data.comments;
          this.isBusyComments = false;
        }
      });
    },

    getLikes() {
      let id = this.$route.params.id;
      window.axios.get('likes/' + id).then((resp) => {
        if (resp.data.success) {
          this.likesItems = resp.data.likes;
        }
      });
    },

    getOrgDetails() {
      let id = this.$route.params.id;
      console.log(id);
      window.axios.get('social-media/' + id).then((resp) => {
        console.log(resp);
        this.socialMediaDetails = resp.data.data;
      });
    },

    deleteComment(id) {
      const comment_id = id;
      let prompt = confirm('Are you sure want to delete the comment? ');
      if (prompt) {
        window
          .axios({
            method: 'delete',
            url: 'comments',
            data: {
              comment_id,
            },
          })
          .then((resp) => {
            if (resp.data.success) {
              this.getComments();
            }
          });
      }
    },

    deleteAdminReply(id) {
      const comment_id = id;
      let prompt = confirm('Are you sure want to delete the comment reply? ');
      if (prompt) {
        window
          .axios({
            method: 'delete',
            url: 'comment/admin',
            data: {
              comment_id,
            },
          })
          .then((resp) => {
            if (resp.data.success) {
              this.getComments();
            }
          });
      }
    },
  },
};
</script>

<style scoped>
/deep/ .dataTable {
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable thead {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}

/deep/ .dataTable caption {
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-left: 27px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 25px;
}

.thead {
  background-color: #dcffd9;
}
</style>
